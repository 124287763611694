let lastScrollY = 0;

const onScroll = () => {

    const header = document.querySelector('.menu-nav');
    const body = document.querySelector('.body');
    const fleche = document.querySelector('.arrow-bottom-right');
    const currentScrollY = window.scrollY;
    

    // si en scroll un peu, la fleche apparrait
    if (currentScrollY > 10) {
        //fleche.style.display = "flex !important";
        fleche.classList.remove('fleche-display');

        
    } else {
        //fleche.style.display = "none !important";
        fleche.classList.add('fleche-display');

    };

    // Ajout de l'evenement sur la fleche pour revenir vers le haut de la page
    fleche.addEventListener('click', () => {
        window.scrollTo({
        top: 0,
        behavior: 'smooth',
        });
    });


    if (currentScrollY > 20) {
        //console.log("La page est en train d'être défilée!");
        header.classList.add('menu-scroll');
    } else if (currentScrollY === 0) {
        // défile vers le haut

        header.classList.remove('menu-scroll');
    }

    lastScrollY = currentScrollY;
};

window.addEventListener("scroll", onScroll);
